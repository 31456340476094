@import '../../../../scss/abstracts/variables';

.helper {
    font-size: 0.9em;
    color: $color-typography-gray--dark;

    &--error {
        color: $color-status-error;
    }

    &--info {
        color: $color-typography-blue;
    }

    &--warning {
        color: $color-status-warning;
    }

    &--success {
        color: $color-status-success;
    }
}
