@import '../../../scss/abstracts/variables';

@mixin backgroundAngle() {
    &:before {
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        right: 0;
        height: 121px;
        width: 103%;
        min-width: 2600px;
        padding-top: 450px;
        transform: translateX(-50%);
        content: url('background-angle.svg');
        background: linear-gradient(0deg, transparent 120px, $color-ui-background-blue--dark 120px);
    }
}

.header--has-global-notification~.background {
    min-height: calc(100vh - 5.125rem - #{$footer-height} - #{$global-notification-height});
}

.background {
    position: relative;
    min-height: calc(100vh - 5.125rem - #{$footer-height});
    background: $color-ui-background-white;
    overflow: hidden;

    &--angle {
        background: $color-typography-gray;

        &>.container {
            z-index: 1;
        }

        /* uses content instead of background because IE/Edge weren't happy with filter being used for dropshadow */
        @include backgroundAngle();
    }

    &--angle-long {
        background: $color-typography-gray;

        &>.container {
            z-index: 1;
        }

        /* uses content instead of background because IE/Edge weren't happy with filter being used for dropshadow */
        @include backgroundAngle();

        &:before {
            padding-top: 550px;
        }

    }

    &--full {
        background: linear-gradient(to bottom, #204b7d 0%, #173960 100%);

        &:before {
            height: 100%;
            width: 100%;
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            background: url('top.svg') no-repeat top;
            background-size: contain;
        }

        &:after {
            height: 100%;
            width: 100%;
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            background: url('bottom.svg') no-repeat bottom;
            background-size: contain;
        }
    }

    &--angle-full {
        background: linear-gradient(0deg, transparent 120px, $color-ui-background-blue--dark 120px);
        background: linear-gradient(to bottom, #204b7d 0%, #173960 100%);
        background: $color-typography-gray;

        &>.container {
            z-index: 1;
        }

        & .background__wave {
            &:before {
                max-width: 1400px;
                max-height: 220px;
                margin: 0 auto;
                height: 100%;
                width: 100%;
                right: 0;
                left: 0;
                content: '';
                position: absolute;
                background: url('top-dark.svg') no-repeat top right;
                background-size: contain;
            }
        }

        @include backgroundAngle();
    }

    &--white {
        background: $color-ui-background-gray;
    }

    &--guardtime {
        padding-bottom: 200px;

        &:after {
            display: block;
            position: absolute;
            width: 215px;
            height: 20px;
            content: url('guardtime.svg');
            left: $page-padding;
            bottom: 20px;

            @media screen and (min-width: #{$page-max-width}) {
                bottom: $page-padding;
            }
        }
    }

    &--no-header {
        min-height: 100vh;
    }

    @media screen and (max-height: 740px) {
        min-height: 670px;
        height: 100%;
        flex: 1 0 auto;
    }
}

.background {
    >.container {
        >.card {
            margin-top: -$card-border-radius;
        }
    }
}