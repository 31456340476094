html, body, #root, #root > div {
    min-height: 100vh;
}

#root > div {
    display: flex;
    flex-direction: column;
}

body {
    margin: 0;
    overflow-x: hidden;
    background: $color-ui-background-gray;

    &.modal-open {
        overflow-y: hidden;
    }

    .pac-container {
        border-width: 0;
        border-style: dashed;
    }
}

.row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;

    &--full>.col:last-of-type {
        padding-right: 0;
    }
}

.col {
    &:first-of-type {
        padding-left: 0;
    }

    &--loose {
        padding: $grid-gutter;
    }

    &--dense {
        padding: 0;
    }

    @include generateGridColumns();
}
