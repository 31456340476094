@import '../../../../scss/abstracts/variables';

.pass-field--strength-bar .textfield__inner:before {
    height: 3px;
    width: 10%;
    content: '';
    position: absolute;
    bottom: 0;
    background: red;
    transition: width 0.5s ease-in-out;
}

.pass-field {
    &.pass-field--strength-bar {
        &.pass-field--20 .textfield__inner:before {
            width: 20%;
            background: red;
        }
        &.pass-field--40 .textfield__inner:before {
            width: 40%;
            background: orange;
        }
        &.pass-field--70 .textfield__inner:before {
            width: 70%;
            background: green;
        }
        &.pass-field--100 .textfield__inner:before {
            width: 100%;
            background: green;
        }
    }
}
