@import '../../../scss/abstracts/variables';

.side-nav {
    @media (min-width: $header-mobile-breakpoint+1) {
        width: 270px;
        position: absolute;
        z-index: 1000;
        right: 3.5rem;
        top: 100%;
        max-height: 0;
        transition: 0.5s;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 0.5rem;
        overflow: hidden;
        box-shadow: $card-box-shadow;

        &__inner {
            background: $color-ui-background-gray--light url(wave.svg) no-repeat 100% 100%;
            background-size: 70% auto;
            border-radius: 3px;
            padding: 0;
        }

        &--open {
            max-height: 100vh;
        }

        .links {
            margin-top: 1rem;
            border-bottom: 2px solid $color-white;

            &__item {
                margin: 0;
                padding: 0.5rem 2.5rem;
                border-top: 2px solid $color-white;
                position: relative;
                &:before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    content: "";
                    width: 5px;
                    background: $color-accent-yellow;
                }
                .btn {
                    font-size: 16px;
                    &__text {
                        font-weight: 500;
                    }
                }
            }
        }
    }

    SECTION {
        padding: 0;
        font-size: 14px;
        margin-bottom: 1rem;
        &.profile {
            padding: 2.5rem 2.5rem 0;
        }
        H2 {
            font-weight: 600;
            color: $color-typography-blue--dark;
            font-size: 24px;
            margin: 0;
        }
        P {
            margin: 0.5em 0 0;
            line-height: 1.2;
            STRONG { display: block; }
        }
    }


    @media (max-width: $header-mobile-breakpoint) {
        background: $color-typography-gray url(wave.svg) no-repeat 100% 100%;
        background-size: auto 90%;
        padding: 1.5rem 1.5rem 1rem;
        box-sizing: border-box;
        position: relative;
        &:before { content: ""; position: absolute; left: -20px; right: -20px; top: 0; height: 10px; box-shadow: inset 0 5px 5px 0 rgba(0,0,0,0.2); }

        SECTION {
            &.profile {
                padding: 0; margin-bottom: 0.5rem;
                H2 { font-size: 18px; }
            }
            &.links {
                margin-bottom: 0; font-size: 16px;
                .btn--link .btn__text { font-weight: 500; }
                UL { display: flex; flex-wrap: wrap; }
                LI { margin-right: 1.5rem; }
            }
        }


    }
}


