@import '../../../scss/abstracts/variables';

.loader {
    border: 5px solid $color-ui-background-gray--light;
    border-top: 5px solid $color-accent-blue;
    border-radius: 50%;
    width: 2em;
    height: 2em;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
