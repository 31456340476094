@import '../../../../scss/abstracts/variables';
@import '../../../../scss/abstracts/mixins';

.textfield {
    position: relative;
    font-size: $form-font-size;
    width: 100%;
    min-width: $form-min-width;
    margin: map_get($size-variables, md) 0;

    &.is-focused {
        z-index: 2;
    }

    &__tooltip {
        position: absolute;
        right: -2rem;
        top: calc(39px);
        .icon {
            fill: $color-status-info;
        }
    }

    &--inverted {
        .textfield__input {
            background-color: $form-invert-background-color;
            border-color: $form-invert-border-color;
            color: $color-typography-gray;
            box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.1);
            &::placeholder {
                color: #5d7792;
            }
        }

        &.is-focused .textfield__inner .textfield__input {
            border-color: $form-invert-border-color--focused;
            outline: $form-invert-border-color--focused 1px solid;
            background-color: rgba(209, 219, 249, 0.1);
        }

        &__inner:hover .textfield__input {
            border-color: $form-invert-border-color--hover;
            outline: $form-invert-border-color--hover 1px solid;
        }
    }

    @include generateFormSizes();
}

.textfield__inner {
    position: relative;
    font-size: inherit;

    &:after {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 3px;
        content: '';
        transform: scaleX(0);
        transition: transform 200ms ease-in-out, background-color 1s ease-out;
    }

    .textfield.has-info &,
    .textfield.is-invalid &,
    .textfield.is-valid & {
        &:after {
            transform: scaleX(1);
        }
    }

    .textfield.has-info &:after {
        background-color: $color-status-info;
    }

    .textfield.is-invalid &:after {
        background-color: $color-status-error;
    }

    .textfield.is-valid &:after {
        background-color: $color-status-success;
    }
}

.textfield__input {
    display: block;
    width: 100%;
    min-width: $form-min-width;
    padding: calc(#{$form-padding-y} - #{$form-border-width}) calc(#{$form-padding-x} - #{$form-border-width});
    border-radius: $form-border-radius;
    font-size: inherit;
    text-align: left;
    border: $form-border-width solid $form-border-color;
    background: $form-background-color;
    resize: none;
    outline: 0;
    transition: border-color $transition-duration ease-out;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }

    &::placeholder {
        color: $color-typography-gray;
    }

    .textfield:not(.is-disabled):not(.is-readonly):hover & {
        border-color: $form-border-color--hover;
        outline: $form-border-color--hover 1px solid;
    }

    .textfield.is-disabled &,
    .textfield.is-readonly & {
        color: hsl(0, 0%, 60%);
        -webkit-text-fill-color: hsl(0, 0%, 60%);
        opacity: 1;
    }

    .textfield.is-focused & {
        border-color: $form-border-color--focused;
        outline: $form-border-color--focused 1px solid;
    }

    .textfield.icon-right & {
        padding-right: calc(((1em - 2px) * 2) + 1em) !important;
    }

    .textfield.icon-left & {
        padding-left: calc(((1em - 2px) * 2) + 1em) !important;
    }
}

.textfield__button {
    display: block;
    position: absolute;
    top: 50%;
    padding: calc(#{$btn-padding-y} - #{$btn-border-width});
    border: 0;
    background: transparent;
    transform: translateY(-50%);
    outline: 0;

    &-icon {
        width: 1em;
        height: 1em;
    }

    &--right {
        right: 0;
    }

    &--left {
        left: 0;
    }

    &.clickable {
        cursor: pointer;
    }
}

textarea {
    height: 100px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: all 5000s ease-in-out 0s;
}
