@mixin generateFormSizes() {
    @each $size, $value in $form-sizes {
        &--#{$size} {
            max-width: map-get($value, max);
            min-width: map-get($value, min);
        }
    }
}

@mixin generateIconSizes() {
    @each $size, $value in $size-variables {
        &--#{$size} {
            height: $value;
            width: $value;
            min-height: $value;
            min-width: $value;
        }
    }
}

@mixin generateGridColumns() {
    max-width: 100% !important;
    padding: calc(#{$grid-gutter} / 2) calc(#{$grid-gutter} * 2);
    margin: 0 !important;

    &--padding-sm {
        padding: calc(#{$grid-gutter} / 2);
    }

    &--padding-md {
        padding: $grid-gutter;
    }

    &--padding-lg {
        padding: calc(#{$grid-gutter} * 2);
    }

    @for $i from 1 through $grid-columns {
        &--#{$i} {
            width: 100% !important;
            max-width: calc(100% / #{$grid-columns} * #{$i}) !important;
        }

        &--xs-#{$i} {
            @media (max-width: #{$break-xs}) {
                max-width: calc(100% / #{$grid-columns} * #{$i}) !important;

                @if $i==$grid-columns {
                    padding-right: 0;
                    padding-left: 0;
                }
            }
        }

        &--sm-#{$i} {
            @media (max-width: #{$break-sm}) {
                max-width: calc(100% / #{$grid-columns} * #{$i}) !important;

                @if $i==$grid-columns {
                    padding-right: 0;
                    padding-left: 0;
                }
            }
        }

        &--md-#{$i} {
            @media (max-width: #{$break-md}) {
                max-width: calc(100% / #{$grid-columns} * #{$i}) !important;

                @if $i==$grid-columns {
                    padding-right: 0;
                    padding-left: 0;
                }
            }
        }

        &--lg-#{$i} {
            @media (max-width: #{$break-lg}) {
                max-width: calc(100% / #{$grid-columns} * #{$i}) !important;

                @if $i==$grid-columns {
                    padding-right: 0;
                    padding-left: 0;
                }
            }
        }

        &--xl-#{$i} {
            @media (max-width: #{$break-xl}) {
                max-width: calc(100% / #{$grid-columns} * #{$i}) !important;

                @if $i==$grid-columns {
                    padding-right: 0;
                    padding-left: 0;
                }
            }
        }

        &--xxl-#{$i} {
            @media (max-width: #{$break-xxl}) {
                max-width: calc(100% / #{$grid-columns} * #{$i}) !important;

                @if $i==$grid-columns {
                    padding-right: 0;
                    padding-left: 0;
                }
            }
        }

        &--xxxl-#{$i} {
            @media (max-width: #{$break-xxxl}) {
                max-width: calc(100% / #{$grid-columns} * #{$i}) !important;

                @if $i==$grid-columns {
                    padding-right: 0;
                    padding-left: 0;
                }
            }
        }
    }
}

@mixin generateDistanceHelpers() {
    @for $i from 0 through 10 {
        .mt-#{$i} {
            margin-top: calc(#{$i} * 0.5rem) !important;
        }

        .mb-#{$i} {
            margin-bottom: calc(#{$i} * 0.5rem) !important;
        }

        .ml-#{$i} {
            margin-left: calc(#{$i} * 0.5rem) !important;
        }

        .mr-#{$i} {
            margin-right: calc(#{$i} * 0.5rem) !important;
        }

        .pt-#{$i} {
            padding-top: calc(#{$i} * 0.5rem) !important;
        }

        .pb-#{$i} {
            padding-bottom: calc(#{$i} * 0.5rem) !important;
        }

        .pl-#{$i} {
            padding-left: calc(#{$i} * 0.5rem) !important;
        }

        .pr-#{$i} {
            padding-right: calc(#{$i} * 0.5rem) !important;
        }

        .mt-neg-#{$i} {
            margin-top: calc(#{$i} * -0.5rem) !important;
        }

        .mb-neg-#{$i} {
            margin-bottom: calc(#{$i} * -0.5rem) !important;
        }

        .ml-neg-#{$i} {
            margin-left: calc(#{$i} * -0.5rem) !important;
        }

        .mr-neg-#{$i} {
            margin-right: calc(#{$i} * -0.5rem) !important;
        }

        .pt-neg-#{$i} {
            padding-top: calc(#{$i} * -0.5rem) !important;
        }

        .pb-neg-#{$i} {
            padding-bottom: calc(#{$i} * -0.5rem) !important;
        }

        .pl-neg-#{$i} {
            padding-left: calc(#{$i} * -0.5rem) !important;
        }

        .pr-neg-#{$i} {
            padding-right: calc(#{$i} * -0.5rem) !important;
        }
    }
}
