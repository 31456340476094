@import '../../../../scss/abstracts/variables';

.label {
    display: flex;
    font-size: 0.9em;
    color: $color-typography-gray--dark;
    flex-direction: row;
    justify-content: space-between;

    &__required {
        text-transform: uppercase;
    }

    &__text {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 0.5rem;
    }

    &__tooltip {
        margin-left: map_get($size-variables, xxs);
    }

    &--inverted {
        .label__text {
            color: $color-typography-gray;
        }
        .label__required {
            color: $color-typography-gray;
        }
    }
}
