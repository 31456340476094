@import '../../../scss/abstracts/variables';
@import '../../../scss/abstracts/mixins';

.group-items {
    display: flex;
    margin: calc(-1rem / 2);

    &-container {
        margin: map_get($size-variables, md) 0;
    }

    &--block {
        flex-direction: column;
        padding: 0;
        margin: calc(-1rem / 2) 0;
    }

    &--inline {
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: calc(-1rem / 2);
        margin-right: calc(-1rem / 2);
    }

    &--inline-flex {
        display: inline-flex;
    }

    &--left {
        justify-content: flex-start;
    }

    &--space-between {
        justify-content: space-between;
    }

    &--center {
        justify-content: center;
    }

    &--right {
        justify-content: flex-end;
    }

    &--v-baseline {
        align-items: baseline;
    }

    &--v-center {
        align-items: center;
    }

    &--v-top {
        align-items: flex-start;
    }

    &--v-bottom {
        align-items: flex-end;
    }
}

.group-item {
    margin-top: (-1rem * 0.5);
    margin-bottom: (-1rem * 0.5);
    list-style: none;
    box-sizing: content-box;

    @include generateFormSizes();

    &--v-baseline {
        align-self: baseline;
    }

    &--v-center {
        align-self: center;
    }

    &--v-top {
        align-self: flex-start;
    }

    &--v-bottom {
        align-self: flex-end;
    }

    &--left {
        justify-content: flex-start;
    }

    &--space-between {
        justify-content: space-between;
    }

    &--center {
        justify-content: center;
    }

    &--right {
        justify-content: flex-end;
    }

    .group-items--flow &,
    &--flow {
        flex: 1 1 1px;
    }

    .group-items--inline & {
        padding: 0 (map_get($size-variables, md) * 0.5);
    }

    &--mobile-full {
        @media (max-width: $header-mobile-breakpoint) {
            min-width: 100%;
        }
    }

    @media (max-width: $header-mobile-breakpoint) {
        margin-top: 0;
        margin-bottom: 0;
        max-width: 100%;
    }
}
