@import '../../../../../scss/abstracts/variables';

.user {
    display: flex;
    flex: 0 1 21rem;
    overflow: hidden;
    align-items: center;
    color: $color-typography-gray;
    cursor: pointer;
}

.user__info {
    position: relative;
    padding: 0 map_get($size-variables, xs);
    text-align: right;
    flex: 0 1 100%;
    overflow: hidden;
}

.user__company {
    max-width: 18rem;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
}

.user__name {
    max-width: 18rem;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.8em;
    white-space: nowrap;
}

.user__icon-wrapper {
    padding-left: map_get($size-variables, xs);
}

.user__icon {
    display: block;
    width: 1.5em;
    height: 1.5em;
    fill: $color-accent-yellow;
    transition: 1s;
    cursor: pointer;

    &--disabled {
        cursor: default;
        fill: $color-accent-blue;
        transition: none;

        &:hover {
            transform: none;
        }
    }

    &:hover {
        transform: rotate(180deg);
    }
}
