@import '../../../scss/abstracts/variables';

.container {
    position: relative;
    min-width: $page-min-width;
    max-width: $page-max-width;
    margin: 0 auto;
    padding: 0 $page-padding;
    width: 100%;
    > .card {
        margin-left: auto;
        margin-right: auto;
    }
    &--onboarding {
        max-width: map_get($container-size-variables, sm);
    }

    &--login {
        max-width: map_get($container-size-variables, xs);
        margin-top: 5rem;
        color: $card-color--inverted;
    }

    &--recovery-by-sms {
        max-width: map_get($container-size-variables, xs);
        margin-top: 2rem;
        color: $card-color--inverted;
    }

    &--dense {
        max-width: 1000px;
    }

    @media (max-width: $header-mobile-breakpoint) {
        padding: 0 $page-padding-mobile;
    }
}
