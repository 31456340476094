@import '../abstracts/variables';
@include generateDistanceHelpers();
/* Margin y */

@each $name, $value in $size-variables {
    .space-y--#{$name} {
        margin: $value 0;
    }
}

@each $name, $value in $size-variables {
    .text-#{$name} {
        font-size: $value;
    }
}

.text-informative {
    font-style: italic;
    font-weight: 200;
}

.text-center {
    text-align: center;
}

.text-underline {
    text-decoration: underline;
}

.text-right {
    text-align: right;
}

.text-bold {
    font-weight: 500;
}

.text-white {
    color: $color-typography-gray;
}

.text-alert {
    color: $color-status-error !important;
}

.text-warning {
    color: $color-status-warning !important;
}

.text-success {
    color: $color-status-success !important;
}

.text-info {
    color: $color-status-info !important;
}

.text-block--left {
    margin-left: -2.5rem;
    padding: 0 0.75rem 0 2.5rem;
    background: $color-ui-background-blue;
    width: calc(100% + 2.5rem);

    &.text-block--padding {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
}

.text-block--right {
    margin-right: -2.5rem;
    padding: 0 2.5rem 0 0.75rem;
    background: $color-ui-background-blue;
    width: calc(100% + 2.5rem);

    &.text-block--padding {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
}

.clickable {
    cursor: pointer;
}

.hidden {
    display: none;
}

.inline {
    display: inline;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-1 {
    margin-bottom: 0.5rem;
}

.mb-2 {
    margin-bottom: 1rem;
}

.mb-3 {
    margin-bottom: 1.5rem;
}

.mb-4 {
    margin-bottom: 2rem;
}

.mb-5 {
    margin-bottom: 2.5rem;
}

.mt-0 {
    margin-top: 0;
}

.mt-1 {
    margin-top: 0.5rem;
}

.mt-2 {
    margin-top: 1rem;
}

.mt-3 {
    margin-top: 1.5rem;
}

.mt-4 {
    margin-top: 2rem;
}

.mt-5 {
    margin-top: 2.5rem;
}

.ml-a {
    margin-left: auto;
}

.ml-0 {
    margin-left: 0;
}

.ml-1 {
    margin-left: 0.5rem;
}

.ml-2 {
    margin-left: 1rem;
}

.ml-3 {
    margin-left: 1.5rem;
}

.ml-4 {
    margin-left: 2rem;
}

.ml-5 {
    margin-left: 2.5rem;
}

.mr-0 {
    margin-right: 0;
}

.mr-1 {
    margin-right: 0.5rem;
}

.mr-2 {
    margin-right: 1rem;
}

.mr-3 {
    margin-right: 1.5rem;
}

.mr-4 {
    margin-right: 2rem;
}

.mr-5 {
    margin-right: 2.5rem;
}

.wb-break-word {
    word-break: break-word;
}

.wb-break-all {
    word-break: break-all;
}
