@import '../../../scss/abstracts/variables';

$tooltip-border: 3px solid $color-typography-gray--dark;
/**
 * https://github.com/wwayne/react-tooltip#options
 * extra custom class, can use !important to overwrite react-tooltip's default class
 */
.tooltip {
    // Overwriting
    opacity: 1 !important;
    background-color: $color-ui-background-white !important;
    color: $color-typography-black !important;
    border-radius: 0 !important;
    font-size: 0.75rem !important;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
    max-width: 300px;
    white-space: normal;

    &.place-top {
        border-bottom: $tooltip-border;
        &:after {
            border-top-color: $color-typography-gray--dark !important;
            bottom: -9px !important;
        }
    }

    &.place-bottom {
        border-top: $tooltip-border;
        &:after {
            border-bottom-color: $color-typography-gray--dark !important;
            top: -9px !important;
        }
    }

    &.place-left {
        border-right: $tooltip-border;
        &:after {
            border-left-color: $color-typography-gray--dark !important;
            right: -9px !important;
        }
    }

    &.place-right {
        border-left: $tooltip-border;
        &:after {
            border-right-color: $color-typography-gray--dark !important;
            left: -9px !important;
        }
    }

    // inside info
    &__header {
        margin: 1rem 0 0 0;
        font-size: 0.875rem !important;
        font-weight: 400;
    }

    &__body {
        font-weight: 200;
        color: $color-typography-black;
        white-space: pre-line;
    }
}
