@import '../../../scss/abstracts/variables';

.notification {
    display: flex;
    position: relative;
    min-height: 3rem;
    margin: map_get($size-variables, md) 0;
    overflow: hidden;
    border: 2px solid;
    border-radius: 1px;
    align-items: stretch;
    padding: 0 0.5rem 0 0;
    color: $color-typography-white;

    &__icon {
        min-width: 3rem;
        min-height: 3rem;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        & > .icon {
            width: map_get($size-variables, xl);
            height: map_get($size-variables, xl);
            fill: $color-ui-background-white;
        }
    }

    &__content {
        position: relative;
        margin-right: 2rem;
    }

    &__text {
        font-size: map_get($text-size-variables, md);
        line-height: 1.25rem;
        margin: map_get($size-variables, md) 0;

        & > a {
            color: inherit;
        }
    }

    &__remove {
        position: absolute;
        top: 0.85rem;
        right: 0.75rem;
        width: map_get($size-variables, md);
        height: map_get($size-variables, md);
        margin: map_get($size-variables, xxs);
        cursor: pointer;
    }

    &--error {
        background: $color-status-error;
        border-color: $color-status-error;
    }

    &--info {
        background: $color-status-info;
        border-color: $color-status-info;
    }

    &--success {
        background: $color-status-success;
        border-color: $color-status-success;
        .notification__icon {
            & > .icon {
                fill: $color-accent-yellow;
            }
        }
    }
}
