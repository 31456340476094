/* STYLE VARIABLES FOR STOCKTREASURY */

/* COLORS */
$color-accent-yellow: #ffd700;
$color-accent-blue: #204b7d;
$color-white: #ffffff;

/* TYPOGRAPHY COLORS */
$color-typography-black: #424242;
/* Body text on light bg */
$color-typography-gray: #dce0e7;
/* Body text on dark bg */
$color-typography-gray--dark: #757575;
/* Label color on light bg */
$color-typography-blue--dark: #112945;
/* Heading color on light bg */
$color-typography-blue--light: #56ccf2;
/* Primary link color */
$color-typography-blue: #0ca0cf;
/* Inverted link color (on light bg) */
$color-typography-white: #ffffff;
/* Used on Tabs selected button */

$color-ui-info: #c4c4c4;

/* BACKGROUND COLORS */
$color-ui-background-white: #fefeff;
$color-ui-background-gray--light: #eef1f6;
$color-ui-background-gray: #fafafb;
$color-ui-background-gray-medium: #bdbdbd;
$color-ui-background-blue--dark: #0f2746;
$color-ui-background-blue--light: rgba(209, 219, 249, 0.2); // used in inverted inputs
$color-ui-background-blue: #1b3f69;
$color-ui-background-light-yellow: rgba(255, 215, 0, 0.25);
$color-ui-background-yellow: rgba(255, 215, 0, 0.5);
$color-ui-background-dark: rgba(27, 63, 105, 0);
$color-ui-background-gradient-light: #008bc1;
$color-ui-background-gradient-dark: #243167;

/* STATUS COLORS */
$color-status-success: #719e10;
$color-status-info: #2d9cdb;
$color-status-warning: #ff9800;
$color-status-error: #eb5757;

$intents: (
    'success': $color-status-success,
    'info': $color-status-info,
    'warning': $color-status-warning,
    'error': $color-status-error,
);

/* PROGRESS COLORS */
$progress-gradient-gray--lightest: #f2f2f2;
$progress-gradient-gray--light: #ebebeb;

/* FONTS */
$font-family: 'Roboto', sans-serif;

$page-min-width: 320px; // Page minimum width
$page-max-width: 1400px; // Page maximum width
$page-padding: 2.5rem;
$page-padding-mobile: 1rem;

// Breakpoints
// TODO should we implement GRID component
$break-xs: 320px; // Extra small (320px - 599px) Use the modifier "-xs" when defining classes.
$break-sm: 600px; // Small (600px - 799px) Use the modifier "-sm" when defining classes.
$break-md: 800px; // Medium (800px - 1000px) Use the modifier "-md" when defining classes.
$break-lg: 1000px; // Large (1000px - 1199px) Use the modifier "-lg" when defining classes.
$break-xl: 1200px; // Extra large (1200px - 1399px) Use the modifier "-xl" when defining classes.
$break-xxl: 1400px; // Extra extra large (1400px - 1599px) Use the modifier "-xxl" when defining classes.
$break-xxxl: 1600px; // Extra extra extra large (1600px - ...) Use the modifier "-xxxl" when defining classes.

// Grid
$grid-columns: 12;
$grid-gutter: 1rem;

/* SIZE VARIABLES */
$container-size-variables: (
    xs: calc(300px + 5rem),
    sm: 600px,
);

$size-variables: (
    xxs: 0.25rem,
    xs: 0.5rem,
    sm: 0.75rem,
    md: 1rem,
    lg: 1.5rem,
    xl: 2rem,
    xxl: 2.5rem,
);

$text-size-variables: (
    xxs: 0.5em,
    xs: 0.75em,
    sm: 0.9em,
    md: 1em,
    lg: 1.5em,
    xl: 2em,
    xxl: 2.5em,
);
/* WHITE-SPACE */
$line-height: 1.5;
/* TRANSITION */
$transition-duration: 150ms;
$transition-long-duration: 250ms;
$transition-timing-function: ease;
$transition-default: $transition-duration $transition-timing-function;
$transition-long: $transition-long-duration $transition-timing-function;
/* FORM */
$form-min-width: 60px;
$form-font-size: 1rem;
$form-padding-x: 1em;
$form-padding-y: 0.5em;
$form-border-width: 1px;
$form-border-radius: 1px;
$form-sizes: (
    ultra-xl: (
        min: 250px,
        max: 750px,
    ),
    xxl: (
        min: 250px,
        max: 550px,
    ),
    xl: (
        min: 200px,
        max: 550px,
    ),
    lg: (
        min: 150px,
        max: 550px,
    ),
    md: (
        min: 150px,
        max: 300px,
    ),
    sm: (
        min: 100px,
        max: 150px,
    ),
    xs: (
        min: 60px,
        max: 100px,
    ),
    xxs: (
        min: 60px,
        max: 60px,
    ),
    none: (
        min: 0,
        max: none,
    ),
);
$form-border-color: #cfd8dc;
$form-border-color--focused: #56ccf2;
$form-border-color--hover: $color-typography-blue--dark;
$form-border-color--error: $color-status-error;
$form-background-color: $color-ui-background-white;
$form-invert-border-color: #315d8f;
$form-invert-border-color--focused: #56ccf2;
$form-invert-border-color--hover: $color-typography-blue--dark;
$form-invert-background-color: $color-ui-background-blue--light;
/* BUTTONS */
$btn-min-width: 125px;
$btn-font-size: 1rem;
$btn-padding-x: 1.5em;
$btn-padding-y: 0.5em;
$btn-border-width: 2px;
$btn-border-radius: 1px;
$btn-text-transform: uppercase;
// MAIN
$btn-main-color: $color-accent-yellow;
$btn-main-color--hover: $color-accent-yellow;
$btn-main-background: $color-ui-background-blue;
$btn-main-background--hover: $color-ui-background-blue;
$btn-main-border: $color-accent-yellow;
$btn-main-border--hover: $color-accent-yellow;
// ALT
$btn-alt-color: $color-ui-background-white;
$btn-alt-color--hover: $color-ui-background-white;
$btn-alt-background: $color-ui-background-blue;
$btn-alt-background--hover: $color-ui-background-blue;
$btn-alt-border: #426b9a;
$btn-alt-border--hover: #426b9a;
// THIRD
$btn-third-color: $color-accent-yellow;
$btn-third-color--hover: #f2c94c;
$btn-third-background: #315d8f;
$btn-third-background--hover: #315d8f;
$btn-third-border: #315d8f;
$btn-third-border--hover: $color-typography-blue--dark;
// RED-BORDER
$btn-red-border-color: $color-status-error;
$btn-red-border-color--hover: $color-status-error;
$btn-red-border-background: transparent;
$btn-red-border-background--hover: transparent;
$btn-red-border-border: $color-status-error;
$btn-red-border-border--hover: $color-status-error;
// TABS-SELECTED
$btn-tabs-selected-color: $color-typography-white;
$btn-tabs-selected-color--hover: $color-typography-white;
$btn-tabs-selected-background: transparent;
$btn-tabs-selected-background--hover: transparent;
$btn-tabs-selected-border: $color-accent-yellow;
$btn-tabs-selected-border--hover: $color-accent-yellow;
// TABS
$btn-tabs-color: $color-typography-gray;
$btn-tabs-color--hover: $color-typography-gray;
$btn-tabs-background: transparent;
$btn-tabs-background--hover: transparent;
$btn-tabs-border: transparent;
$btn-tabs-border--hover: transparent;
// TABS-ALT
$btn-tabs-alt-color: $color-accent-yellow;
$btn-tabs-alt-color--hover: $color-accent-yellow;
$btn-tabs-alt-background: transparent;
$btn-tabs-alt-background--hover: transparent;
$btn-tabs-alt-border: transparent;
$btn-tabs-alt-border--hover: transparent;
// TABS-SELECTED ALT
$btn-tabs-selected-alt-color: $color-ui-background-blue--dark;
$btn-tabs-selected-alt-color--hover: $color-ui-background-blue--dark;
$btn-tabs-selected-alt-background: linear-gradient(to bottom right, #ffd600, #fdec53);
$btn-tabs-selected-alt-background--hover: $color-accent-yellow;
$btn-tabs-selected-alt-border: $color-accent-yellow;
$btn-tabs-selected-alt-border--hover: $color-accent-yellow;
// LINK
$btn-link-color: $color-typography-blue--light;
$btn-link-color--hover: $color-typography-blue--light;
$btn-link-accent-color: $color-accent-yellow;
$btn-link-accent-color--hover: $color-accent-yellow;
$btn-link-accent-inverted-color: $color-accent-blue;
$btn-link-accent-inverted-color--hover: $color-accent-blue;
$btn-link-inverted-color: $color-typography-blue;
$btn-link-inverted-color--hover: $color-typography-blue;
$btn-icon-padding: calc(#{$btn-padding-x} - #{$btn-border-width});
$btn-icon-spacing: 8px;
// CARD
$card-border-radius: 3px;
$card-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.25);
$card-background: $color-ui-background-white;
$card-background--inverted: linear-gradient(to bottom, $color-accent-blue, $color-ui-background-blue);
$card-color: $color-typography-black;
$card-color--inverted: $color-typography-gray;
// HEADER
$header-mobile-breakpoint: 900px;
$header-desktop-narrow-breakpoint: 1160px;
// FOOTER
$footer-height: 80px;
$footer-height-mobile: 170px;
// GLOBAL NOTIFICATION
$global-notification-height: 60px;
