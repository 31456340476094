@import '../../../../scss/abstracts/variables';

.ReactPhoneNumberInput {
  .PhoneInputCountry {
    padding: 8px;
    border: 1px solid rgb(207, 216, 220);
    margin-right: 0 !important;
    border-right: 0;
    background: white;
    color: black;
  }
  .PhoneInputInput {
    height: 38px;
    font-size: 15px;
    outline: none;
    border: 1px solid rgb(207, 216, 220);
  }
  .PhoneInputCountryIcon {
    background: none !important;
    width: 15px !important;
    height: 10px !important;
  }

  .PhoneInputCountrySelect {
    background: white;
  }
  &.is-invalid > input {
    border-bottom: 3px solid $color-status-error;
  }
  &.full-invalid.PhoneInput--focus {
    outline: 2px solid $color-status-error;
  }
}

.PhoneInput--disabled {
  background: white;
  .PhoneInputInput {
    cursor: not-allowed;
  }
}
