@import '../../../../../../scss/abstracts/variables';

.credentials {
    max-width: 100%;
    &__value {
        color: $color-typography-gray--dark;
    }

    &__verified {
        color: $color-status-success;
    }

    &__unverified {
        color: $color-status-error;
    }

    &__add {
        margin-top: 44px;
    }

    // Dashboard App verification to line up.
    & &--no-padding {
        padding: 0;
    }

    table {
        display: block;
        width: 100%;
        border: none;
        white-space: nowrap;
        td {
            padding: 4px 30px 4px 0;
        }
        @media (max-width: $header-mobile-breakpoint) {
            > thead,
            > tbody,
            tfoot,
            tr,
            td,
            th { display: block; }

            th:not(.text-right) { display: none; }
            tr { margin: $grid-gutter * 0.5 0; padding: 0; }
            td {
                word-wrap: break-word;
            }
        }
    }
}

.phone-number-item {
    min-width: 300px;
}

.react-phone-number-input__country--native {
    z-index: 30;
    position: absolute;
    padding-left: 10px;
    bottom: 4px;
}

.react-phone-number-input__icon-image {
    margin-bottom: 2px;
}

.react-phone-number-input__input {
    position: relative;
    height: 61px;
    margin: 0;
}

.react-phone-number-input__row {
    position: relative;

    .textfield__input {
        padding-left: 50px;
    }
}

.react-phone-number-input {
    margin: 1rem 0;
}

.react-phone-number-input__input {
    border-bottom: 0;
}

.phone-number-item {
    min-width: 300px;
    .react-tel-input {
        height: 40px;
        input {
            height: 40px;
            border-radius: 1px !important;
        }
    }
    @media (max-width: $header-mobile-breakpoint) {
        min-width: 100%;
        box-sizing: border-box;
        .react-tel-input .form-control { width: 100%; box-sizing: border-box; }
    }
}
