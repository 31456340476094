@import '../../../scss/abstracts/variables';

.header {
    z-index: 21;
    position: relative;
    background: $color-ui-background-blue--dark;
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.25);
    font-size: 1.12rem;
    & > .container {
        display: flex;
        align-items: center;
        height: 5.125rem;
    }
    &--center {
        > .container {
            justify-content: center;
        }
    }
}

.header__branding {
    flex: 0 0 7rem;
}

.header__logo {
    width: 100%;
    height: 3rem;
}

.header__nav {
    flex: 1 1 1px;
    padding: 0 1rem;
}

.header__nav-container {
    display: flex;
    flex: 1;
    overflow: hidden;

    @media (max-width: $header-mobile-breakpoint) {
        display: none;
        position: fixed;
        top: 82px;
        background: $color-ui-background-blue--dark;
        left: 0;
        right: 0;
        bottom: 0;
        flex-direction: column;
        overflow-y: auto;

        body.modal-open &--mobile-visible {
            display: flex;
        }

        & .header__nav { order: 1 }

        & .user {
            display: none;
        }

        /*& .user {
            justify-content: flex-end;
            padding: 20px 30px;
            flex: 0 0 auto;
            flex-direction: row-reverse;
            background: $color-white;
            order: 0;
        }
        & .user__info { text-align: left; }
        & .user__company {
            color: $color-typography-black;
        }

        & .user__name {
            color: $color-typography-gray--dark;
        }*/

        & .nav__list {
            flex-direction: column;
        }
    }
    @media (max-width: $header-desktop-narrow-breakpoint) {
        & .nav__link {
            padding: 0.75rem;
        }
        & .user__company {
            font-size: 14px;
        }
    }
}

.header__action {
    padding: 0 1rem;

    & button {
        color: $color-typography-gray;
    }
}

#header-menu-toggle {
    display: none;
}

.nav__item.mobile-only {
    display: none;
}

@media (max-width: $header-mobile-breakpoint) {
    .nav__item.mobile-only {
        display: inherit;
    }

    #header-menu-toggle {
        display: block;
        position: absolute;
        right: 20px;
        top: 30px;
        z-index: 1;
        user-select: none;

        & > a {
            text-decoration: none;
            color: #232323;

            transition: color 0.3s ease;
        }

        & > a:hover {
            color: tomato;
        }

        & > input {
            display: block;
            width: 40px;
            height: 32px;
            position: absolute;
            top: -7px;
            left: -5px;
            cursor: pointer;
            opacity: 0;
            z-index: 2;
            -webkit-touch-callout: none;
        }

        & > span {
            display: block;
            width: 33px;
            height: 4px;
            margin-bottom: 5px;
            position: relative;
            background: #cdcdcd;
            border-radius: 3px;
            z-index: 1;
            transform-origin: 4px 0px;
            transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
                background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
        }

        & > span:first-child {
            transform-origin: 0% 0%;
        }

        & > span:nth-last-child(2) {
            transform-origin: 0% 100%;
        }

        & > input:checked ~ span:nth-last-child(1) {
            opacity: 1;
            transform: rotate(-45deg) translate(0px, 0px);
        }

        & > input:checked ~ span:nth-last-child(3) {
            opacity: 0;
            transform: rotate(0deg) scale(0.2, 0.2);
        }

        & > input:checked ~ span:nth-last-child(2) {
            transform: rotate(45deg) translate(-8px, -9px);
        }

        .header__nav-container li {
            padding: 10px 0;
            font-size: 22px;
        }

        & > input:checked ~ ul {
            transform: none;
        }
    }
}
