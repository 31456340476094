@import '../../../../scss/abstracts/variables';

.dashboard-notification {
    position: relative;
    color: $color-typography-white;
    overflow: hidden;
    animation: dropdown;
    animation-duration: 0.5s;
    z-index: 20;
    box-shadow: 2px 4px 5px 0 rgba(0, 0, 0, 0.25);

    &--success {
        border: 2px solid $color-status-success;
        background: $color-status-success;
    }

    &--error {
        border: 2px solid $color-status-error;
        background: $color-status-error;
    }

    &--info {
        border: 2px solid $color-status-info;
        background: $color-status-info;
    }

    &__icon {
        fill: $color-accent-yellow;
    }

    &__link.btn--link {
        color: currentColor;
    }

    &__title {
        font-size: 1.5rem;
        font-weight: 500;
    }

    &__text {
        font-weight: 200;
    }

    &__remove {
        position: absolute;
        top: 0.55rem;
        right: 0;
        width: 1.5rem;
        height: 1.5rem;
        margin: 0.25rem;
        cursor: pointer;
    }
}

@keyframes dropdown {
    0% {
        height: 0;
    }

    100% {
        height: 64px;
    }
}
