@import '../../../scss/abstracts/variables';

.placeholder {
    position: relative;
    box-sizing: content-box;
    margin: map_get($size-variables, md) 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $color-typography-gray--dark;

    .card--inverted & {
        color: $color-typography-gray;
    }
}

.placeholder__icon {
    height: 4.25rem;
    width: 100%;
    fill: $color-typography-gray--dark;

    .card--inverted & {
        fill: $color-typography-gray;
        opacity: 0.2;
    }
}
