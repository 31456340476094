@import '../../../scss/abstracts/variables';

.global-notification {
    height: $global-notification-height;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $color-white;
    font-weight: 400;
    font-size: 16px;
    padding: $grid-gutter * 0.5 0;
    @each $intent, $color in $intents {
        &--intent-#{$intent} {
            background-color: $color;
        }
    }
    @media (max-width: $break-sm) {
        height: auto;
        font-size: 14px;
    }
    .container {
        display: flex;
        align-items: center;
    }
    .icon {
        width: 1.25rem;
        height: 1.25rem;
        flex: 0 0 1.25rem;
        padding-right: $grid-gutter;
        @media (max-width: $break-sm) {
            flex: 0 0 1rem;
            width: 1rem;
            height: 1rem;
            padding-right: 0.75rem;
        }
    }
}
