@import '../../../../../scss/abstracts/variables';

.nav {
    margin: 1rem 0;
}

.nav__list {
    display: flex;
    align-items: center;
}

.nav__link {
    display: block;
    position: relative;
    padding: 0.75rem 1.6rem;
    color: $color-typography-gray;
    text-align: center;
    white-space: nowrap;
    outline: 0;
    font-weight: 400;
    &:before {
        content: '';
        position: absolute;
        width: 80%;
        height: 3px;
        bottom: 3px;
        left: 10%;
        background-color: $color-accent-yellow;
        visibility: hidden;
        transform: scaleX(0);
        transform-origin: center;
        transition: all 0.2s ease-in-out;
    }
    &--disabled {
        pointer-events: none;
        opacity: 0.35;
    }
    &.is-active,
    &:hover,
    &:focus {
        &:before {
            visibility: visible;
            transform-origin: center;
            transform: scaleX(1);
        }
    }
}
