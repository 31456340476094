.route-loading {
    align-items: center;
    text-align: center;
    &__broken {
        width: 90px;
        height: 125px;
    }

    &__button {
        margin-top: 80px;
        width: 180px;
    }

    &__loader {
        position: fixed;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
}
