@import '../../../scss/abstracts/variables';

.error-boundary-fallback-wrapper {
  background-color: #0f2746;
}

.error-boundary-fallback {

    color: $color-typography-white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 45vh;
    max-width: 530px;
    margin: auto;
    @media (max-width: $break-sm) {
        height: auto;
        margin-top: $grid-gutter * 3;
    }
    P { white-space: pre-line; }
    .icon {
        width: 70px;
        height: 70px;
        margin: auto;
        fill: $color-accent-yellow;
    }
}
