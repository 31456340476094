@import '../../../scss/abstracts/variables';

@mixin button-variant($color, $background, $background-h, $border, $border-h, $color-h: null, $box-shadow-h: null) {
    color: $color;
    background: $background;
    border-color: $border;

    &:hover {
        background-color: $background-h;
        border-color: $border-h;

        @if ($color-h) {
            color: $color-h;
        } @else {
            color: $color;
        }

        @if ($box-shadow-h) {
            box-shadow: $box-shadow-h;
        }
    }

    &:disabled {
        color: rgba($color, 0.5);

        &:hover {
            background-color: $background;
            border-color: $border;
            color: rgba($color, 0.5);

            &:before {
                border: 0;
            }
        }
    }
}

@mixin button-border-animation() {
    &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        border: 8px solid transparent;
        border-bottom-color: inherit;
        border-left-color: inherit;
        transition: transform $transition-long;
        will-change: transform;
        transform: translate(calc(-50% - 1px), calc(50% + 1px));
    }
    &:hover:before {
        transform: translate(0);
    }
}

@mixin button-link-variant($color, $color-h, $weight-h: null) {
    color: $color;

    &:focus,
    &:hover {
        background-color: transparent;
        color: $color-h;

        /* TODO can't set weight since it makes the button larger */
        @if ($weight-h) {
            font-weight: $weight-h;
        }
    }

    &.btn--disabled {
        color: rgba($color, 0.5);

        &:hover {
            background-color: transparent;
            color: rgba($color, 0.5);
        }
    }
}

/*
Button base styles
*/
.btn {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    background-image: none;
    min-width: $btn-min-width;
    max-width: 100%;
    font-size: $btn-font-size;
    font-weight: 400;
    text-transform: $btn-text-transform;
    text-align: center;
    border: $btn-border-width solid;
    border-radius: $btn-border-radius;
    padding: calc(#{$btn-padding-y} - #{$btn-border-width}) calc(#{$btn-padding-x} - #{$btn-border-width});
    cursor: pointer;
    box-sizing: border-box;
    outline: 0;
    transition: $transition-default;
    transition-property: background-color, border-color, color;
    overflow: hidden;
    &:disabled {
        cursor: inherit;
    }

    &--block {
        display: block;
        width: 100%;
        margin: 1rem 0;
    }

    &--big {
        height: 50px;

        .btn__text {
            line-height: 35px;
            white-space: nowrap;
        }

        &.btn:before {
            border-width: 10px;
        }
    }
}

.btn__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: inherit;
    position: relative;
    top: 0;
    left: 0;
}

/* *
 * Button types */
.btn--main {
    @include button-variant(
        $btn-main-color,
        $btn-main-background,
        $btn-main-background--hover,
        $btn-main-border,
        $btn-main-border--hover,
        $btn-main-color--hover
    );
    &:disabled {
        &:before {
            display: none;
        }
    }
    &-yellow {
        border-color: $color-accent-blue;
        background-color: $btn-main-color;
        color: $color-accent-blue;
        @include button-border-animation();
        &:disabled {
            background-color: rgba($btn-main-color, 0.5);
        }
    }
    @include button-border-animation();
}

.btn--alt {
    @include button-variant(
        $btn-alt-color,
        $btn-alt-background,
        $btn-alt-background--hover,
        $btn-alt-border,
        $btn-alt-border--hover,
        $btn-alt-color--hover
    );
    &-yellow {
        background-color: $color-accent-blue;
        border-color: #fcd839;
        color: #fcd839;
        @include button-border-animation();
        &:disabled {
            border-color: rgba(#fcd839, 0.5);
            color: rgba(#fcd839, 0.5);
            &:before {
                display: none;
            }
        }
    }
    @include button-border-animation();
}

.btn--third {
    text-transform: none;

    @include button-variant(
        $btn-third-color,
        $btn-third-background,
        $btn-third-background--hover,
        $btn-third-border,
        $btn-third-border--hover,
        $btn-third-color--hover
    );

    @include button-border-animation();
}

.btn--red-border {
    @include button-variant(
        $btn-red-border-color,
        $btn-red-border-background,
        $btn-red-border-background--hover,
        $btn-red-border-border,
        $btn-red-border-border--hover,
        $btn-red-border-color--hover
    );

    @include button-border-animation();
}

.btn--tabs-selected {
    text-transform: none;
    padding-left: 0;
    padding-right: 0;

    @include button-variant(
        $btn-tabs-selected-color,
        $btn-tabs-selected-background,
        $btn-tabs-selected-background--hover,
        $btn-tabs-selected-border,
        $btn-tabs-selected-border--hover,
        $btn-tabs-selected-color--hover
    );

    border-right: 0;
    border-top: 0;
    border-left: 0;
    border-image: linear-gradient(to bottom right, #ffd600, #fdec53) 0 100%;
    border-image-slice: 2;
    border-bottom-width: 4px;

    .btn__inner {
        justify-content: left;
    }
}

.btn--tabs {
    padding-left: 0;
    padding-right: 0;
    text-transform: none;

    @include button-variant(
        $btn-tabs-color,
        $btn-tabs-background,
        $btn-tabs-background--hover,
        $btn-tabs-border,
        $btn-tabs-border--hover,
        $btn-tabs-color--hover
    );
    border: 0;

    .btn__inner {
        justify-content: left;
    }
}

.btn--tabs-selected-alt {
    text-transform: none;

    @include button-variant(
        $btn-tabs-selected-alt-color,
        $btn-tabs-selected-alt-background,
        $btn-tabs-selected-alt-background--hover,
        $btn-tabs-selected-alt-border,
        $btn-tabs-selected-alt-border--hover,
        $btn-tabs-selected-alt-color--hover
    );

    border-image: linear-gradient(to bottom right, #ffd600, #fdec53) 0 100%;
    border-image-slice: 2;
}

.btn--tabs-alt {
    text-transform: none;

    @include button-variant(
        $btn-tabs-alt-color,
        $btn-tabs-alt-background,
        $btn-tabs-alt-background--hover,
        $btn-tabs-alt-border,
        $btn-tabs-alt-border--hover,
        $btn-tabs-alt-color--hover
    );

    border: 0;
}

.btn--content-left {
    text-align: left;

    & .btn__inner {
        justify-content: left;
    }
}

.btn--content-center {
    text-align: center;

    & .btn__inner {
        justify-content: center;
    }
}

.btn--content-right {
    text-align: right;

    & .btn__inner {
        justify-content: right;
    }
}

/* Icons */
.btn__icon.icon {
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
    fill: currentColor;
    pointer-events: auto;

    .btn--small & {
        font-size: 10px;
    }

    .btn--link & {
        font-size: 0.875em;
    }
}

.btn__icon--left.icon {
    margin-right: $btn-icon-spacing;

    .btn--link & {
        margin-left: 0;
        margin-right: 0.5em;
    }
}

.btn__icon--right.icon {
    margin-left: $btn-icon-spacing;

    .btn--link & {
        margin-left: 0.5em;
        margin-right: 0;
    }
}

/* *
 * Link */
.btn--link {
    background: transparent;
    border: 0;
    text-transform: none;
    text-align: inherit;
    font-size: 1em;
    height: auto;
    padding-left: 0;
    padding-right: 0;
    min-width: 0;
    line-height: inherit;
    border-radius: 0; // Used on light background as basic link

    @include button-link-variant($btn-link-color, $btn-link-color--hover);

    // Used as add
    &.btn--link-accent {
        @include button-link-variant($btn-link-accent-color, $btn-link-accent-color--hover);
    }

    // Used in userhub
    &.btn--link-accent-inverted {
        @include button-link-variant($btn-link-accent-inverted-color, $btn-link-accent-inverted-color--hover);
    }

    // Used as show more
    &.btn--link-inverted {
        @include button-link-variant($btn-link-inverted-color, $btn-link-inverted-color--hover);
    }

    &.btn--disabled {
        pointer-events: none;
        cursor: not-allowed;
    }

    .btn__text {
        position: relative;
        font-weight: 400;
    }

    .btn__inner .btn__text::after {
        display: block;
        content: '';
        border-bottom: 1px solid currentColor;
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
        position: absolute;
        left: 0;
        bottom: 2px;
        width: 100%;
    }

    &:hover .btn__text::after {
        transform: scaleX(1);
    }
}

.width-auto {
    width: auto;

    &__add {
        margin-top: 44px;
    }
}

/** Loading */
.btn--loading {
    .loader,
    .loader:after {
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
    }

    .loader {
        margin: 0 0.5rem;
        font-size: 10px;
        position: relative;
        text-indent: -9999em;
        border-top: 0.1rem solid rgba(255, 255, 255, 0.8);
        border-right: 0.1rem solid rgba(255, 255, 255, 0.8);
        border-bottom: 0.1rem solid rgba(255, 255, 255, 0.8);
        border-left: 0.1rem solid $color-ui-background-blue;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: load8 1.1s infinite linear;
        animation: load8 1.1s infinite linear;
    }

    @-webkit-keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
}
