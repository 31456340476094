@import '../../../scss/abstracts/variables';

.background--angle + .footer {
    border-top: 2px solid darken($color-typography-gray, 5%);
    background-color: $color-typography-gray;
}

.background--white + .footer {
    border-top: 2px solid $color-typography-gray;
    background-color: $color-ui-background-gray;
}

.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $grid-gutter;
    height: $footer-height;
    flex-wrap: wrap;
    @media (max-width: $break-lg) {
        height: auto;
        min-height: $footer-height;
    }
    @media (max-width: $break-sm) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.footer__links {
    display: flex;
    flex-direction: row;
    @media (max-width: $break-sm) {
        flex-direction: column;
        align-items: flex-start;
    }

    &--left {
        flex: 1 1 auto;
    }

    &--right {
        flex: 0 0 auto;
    }
}

.footer__link {
    color: $color-typography-blue--dark;

    .nav__link {
        color: $color-typography-blue--dark;
        font-size: .9rem;
        padding: 0.75rem 1rem;

        &:before {
            content: none;
        }

        @media (max-width: $break-sm) {
            font-size: 1rem;
        }
    }

    A {
        transition: color 200ms ease;
        color: $color-typography-blue--dark;

        &:hover {
            color: $color-typography-blue;
        }
    }

    B {
        font-weight: 500;
    }

    svg {
        margin-right: $grid-gutter * 0.5;
        height: 26px;
        max-width: 22px;
        color: $color-typography-blue--dark !important;
        opacity: .3;
    }

    &__inner {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $grid-gutter * 0.5 $grid-gutter;
    }
}

.footer__logo {
    padding: 0 $grid-gutter;
    width: 16px;
    opacity: .2;
    @media (max-width: $break-lg) {
        display: none;
    }
}
