@import '../../../scss/abstracts/variables';
@import '../../../scss/abstracts/mixins';

.icon {
    display: block;
    width: 3rem;
    height: 3rem;
    box-sizing: content-box;
    fill: currentColor;
    vertical-align: middle;

    @include generateIconSizes();

    &-search {
        fill: $color-typography-gray;
    }

    &--clickable {
        cursor: pointer;
    }
}
