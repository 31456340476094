@import '../abstracts/variables';

* {
    box-sizing: border-box;
}

html,
body {
    scroll-behavior: smooth;
}

ul,
ol {
    margin: 0;
    padding-left: 0;
    list-style: none;
}

a {
    text-decoration: none;
}

// Hide IE password eye and x
input::-ms-clear,
input::-ms-reveal {
    display: none;
}

hr {
    display: block;
    margin: 1.5rem 0;
    border: none;
    height: 1px;
    background: rgba($color-white, 0.3);
    width: 100%;
    &.theme--dark {
        background: $color-ui-background-gray-medium;
    }
}
