@use "sass:list";

body {
    font: 300 list.slash(16px, $line-height) $font-family;
}

h1,
.h1 {
    font-weight: 500;
}

h2,
.h2 {
    font-size: 2rem;
    line-height: 1em;
    margin: 0.75em 0;
    font-weight: 500;
}

h3,
.h3 {
    font-size: 1.125rem;
    line-height: 1em;
    margin: 0.75em 0;
    font-weight: 500;
}

input,
textarea,
select,
button {
    /* Note to self (Anees): This might break */
    font: 400 list.slash(1rem, $line-height) $font-family;
}
